SIZE = 25

board = Array.from(Array(SIZE), () => new Array(SIZE))

map = Array.from(Array(SIZE), () => new Array(SIZE))

player = {'x': parseInt(SIZE / 2), 'y': parseInt(SIZE / 2)}
enemies = []
score = 0
isEnd = false

Number.prototype.between = function(a, b) {
    var min = Math.min.apply(Math, [a, b]),
      max = Math.max.apply(Math, [a, b]);
    return this >= min && this <= max;
}

function createBoard() {
    for (x = 0; x < SIZE; x++) {
        row = document.createElement('tr')
        for (y = 0; y < SIZE; y++) {
            col = document.createElement('td')
            col.classList.add('block')
            board[x][y] = col
            row.appendChild(col)
        }
        document.getElementById('board').appendChild(row)
    }
}

function syncBoard() {
    for (x = 0; x < SIZE; x++) {
        for (y = 0; y < SIZE; y++) {
            if (map[x][y]) {
                board[x][y].classList.value = map[x][y] + ' block'
            } else {
                board[x][y].classList.value = 'block'
            }
        }
    }
    document.getElementById('score').innerText = score
}

function syncData() {
    map = Array.from(Array(SIZE), () => new Array(SIZE))
    for (enemy of enemies) {
        map[enemy.x][enemy.y] = 'E'
    }

    if (map[player.x][player.y] == 'E') { map[player.x][player.y] = 'D'; return false; }
    else { map[player.x][player.y] = 'P'; return true; }
}

function spawnEnemy(th) {
    new_enemy = {id: score, x: SIZE - 1, y: SIZE - 1}
    if ([0, 1].includes(th)) new_enemy.x = 0
    if ([0, 3].includes(th)) new_enemy.y = 0
    enemies.push(new_enemy)
}

function moveEnemy() {
    for (enemy of enemies) {
        enemy.dx = player.x - enemy.x
        enemy.dy = player.y - enemy.y

        enemy.dx = ((score + enemy.id) % 2 || enemy.dy == 0) && enemy.dx != 0 ? (enemy.dx > 0 ? 1 : -1) : 0
        enemy.dy = ((score + enemy.id + 1) % 2 || enemy.dx == 0) && enemy.dy != 0 ? (enemy.dy > 0 ? 1 : -1) : 0

    }

    for (enemy of enemies) {
        enemy.x += enemy.dx
        enemy.y += enemy.dy
    }
}

function gameOver() {
    document.getElementById('over').innerText = "게임 오버! 최종"
    isEnd = true
}

function move(dx, dy) {
    if ( !(player.x + dx).between(0, SIZE - 1) || !(player.y + dy).between(0, SIZE - 1) ) return
    if (isEnd) return
    player.x += dx
    player.y += dy
    moveEnemy()
    if (score % 3 == 0) {
        spawnEnemy((score / 3) % 4)
    }

    survived = syncData()
    if (survived) score += 1

    syncBoard()

    if (!survived) gameOver()
}

function init() {
    createBoard()
    syncData()
    syncBoard()
}

moveUp = () => move(-1, 0)
moveDown = () => move(1, 0)
moveRight = () => move(0, 1)
moveLeft = () => move(0, -1)

window.addEventListener('keydown', function(event) {
    const callback = {
        "ArrowUp"    : moveUp,
        "ArrowDown"  : moveDown,
        "ArrowRight" : moveRight,
        "ArrowLeft"  : moveLeft,
        "KeyW"    : moveUp,
        "KeyS"  : moveDown,
        "KeyD" : moveRight,
        "KeyA"  : moveLeft,
    }[event.code]
    callback?.()
});

let pageWidth = window.innerWidth || document.body.clientWidth;
let treshold = Math.max(1,Math.floor(0.01 * (pageWidth)));
let touchstartX = 0;
let touchstartY = 0;
let touchendX = 0;
let touchendY = 0;

const limit = Math.tan(45 * 1.5 / 180 * Math.PI);

document.addEventListener('touchstart', function(event) {
    touchstartX = event.changedTouches[0].screenX;
    touchstartY = event.changedTouches[0].screenY;
}, false);

document.addEventListener('touchend', function(event) {
    touchendX = event.changedTouches[0].screenX;
    touchendY = event.changedTouches[0].screenY;
    handleGesture(event);
}, false);

function handleGesture(e) {
    let x = touchendX - touchstartX;
    let y = touchendY - touchstartY;
    let xy = Math.abs(x / y);
    let yx = Math.abs(y / x);
    if (Math.abs(x) > treshold || Math.abs(y) > treshold) {
        if (yx <= limit) {
            if (x < 0) {
                moveLeft()
            } else {
                moveRight()
            }
        }
        if (xy <= limit) {
            if (y < 0) {
                moveUp()
            } else {
                moveDown()
            }
        }
    }
}

document.getElementById('how-to-play').addEventListener('click', function(event) {
    alert("게임 방법: 화살표 또는 WASD로 움직이면서 적을 잘 피하면 됨. 죽으면 초기화/새로고침(Ctrl+R) ㄱ")
})

init()